<div mat-dialog-title>
  <div class="justify-between">
    <h1 class="font-20px fw-600">
      {{ title() }}
    </h1>
    
    @if(close()) {
      <roam-icon mat-dialog-close name="close" />
    }
    <ng-content select="[buttonTopRight]"></ng-content>
  </div>
</div>

<div mat-dialog-content class="mt-16">
  <ng-content select="[dialogBody]"></ng-content>
</div>

<mat-dialog-actions align="start" class="mat-dialog-action">
  <ng-content select="[actButton]"></ng-content>
</mat-dialog-actions>
